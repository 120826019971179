import {Stack} from "@mui/system";
import {NftRequestProps} from "../types";
import {
    Button, Card, Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    InputAdornment, TextareaAutosize,
    TextField,
} from "@mui/material";
import {getCenterOfPolygon} from "../../../helpers/geolib";
import {useEffect, useState} from "react";
import Loader from "../../../components/Loader/Loader";
import {Map} from "@mui/icons-material";
import {RequestInfoDialog} from "./RequestInfoDialog";
import useNftRequests from "../useNftRequests";
import moment from "moment";
import {MuiFileInput} from "mui-file-input";
import web3storage from "../../../services/web3storage";
import {FileList} from "./FileList";
import {LogList} from "./LogList";
import {MapModal} from "./MapModal/MapModal";

export type ModifyRequestModalProps = {
    request: NftRequestProps;
    canApprove: boolean;
    // loading: boolean;
    onClose: () => void;
    onSave: (req: NftRequestProps) => void;
    onSaveAndApprove: (req: NftRequestProps) => void;
    onApprove: (req: NftRequestProps) => void;
};

const secondaryButtonStyle = {color: "white", backgroundColor: "#1BAF92"};

export const ModifyRequestModal = (props: ModifyRequestModalProps) => {
    const {user, editRequest, approveRequest, saveFile} = useNftRequests();
    const [name, setName] = useState<string>(props.request.propertyName);
    const [file, setFile] = useState<File | null>(null);
    const [loading, setIsLoading] = useState<boolean>(false);
    const [fileListOpen, setFileListOpen] = useState<boolean>(false);
    const [logListOpen, setLogListOpen] = useState<boolean>(false);
    const [mapOpen, setMapOpen] = useState<boolean>(false);

    const [area, setArea] = useState<string>(props.request.area.toString());
    const [density, setDensity] = useState<string>(
        props.request.density.toString()
    );

    const initialCommission =
        props.request.commission ?? props.request.activity_info?.commission ?? 30;
    const initialDuration =
        props.request.duration ?? props.request.activity_info?.duration ?? 30;
    const initialGNDVI =
        props.request.gndvi ?? 1
    const initialPermanencyFactor =
        props.request.permanency_factor_value ?? 0

    const [commision, setCommision] = useState<number>(initialCommission);

    const [duration, setDuration] = useState<number>(initialDuration);
    const [gndvi, setGndvi] = useState<string>(initialGNDVI.toString());
    const [permanencyFactorValue, setPermanencyFactorValue] = useState<string>(initialPermanencyFactor.toString());

    const [requestInfoDialogOpen, setRequestInfoDialogOpen] =
        useState<boolean>(false);

    const handleClickOpen = () => {
        setRequestInfoDialogOpen(true);
    };

    const [isModified, setIsModified] = useState<boolean>(false);

    useEffect(() => {
        setIsModified(
            file != null ||
            name != props.request.propertyName ||
            area != props.request.area.toString() ||
            density != props.request.density.toString() ||
            gndvi != props.request.gndvi.toString() ||
            permanencyFactorValue != props.request.permanency_factor_value?.toString() ||
            commision != initialCommission ||
            duration != initialDuration
        );
    }, [name, area, density, file, commision, duration, gndvi, permanencyFactorValue]);

    const openMap = () => {
        setMapOpen(true);
    };

    const openImage = () => {
        const img = props.request.img;
        if (img) {
            window.open(img, "_blank");
        }
    };

    const openAdditionalFile = () => {
        const img = props.request.other_documentation;
        if (img) {
            window.open(img, "_blank");
        }
    };

    const getModifiedRequest = () => {
        const modifiedReq: NftRequestProps = {...props.request};
        const newArea = Number(area);
        const newDensity = Number(density);
        const newGndvi = Number(gndvi);
        const newPermanencyFactor = Number(permanencyFactorValue);

        if (name && newArea && newDensity && newGndvi && newPermanencyFactor) {
            modifiedReq.propertyName = name;
            modifiedReq.area = newArea;
            modifiedReq.density = density;
            modifiedReq.duration = duration;
            modifiedReq.commission = commision;
            modifiedReq.gndvi = newGndvi;
            modifiedReq.permanency_factor_value = newPermanencyFactor
        } else {
            return null;
        }

        return modifiedReq;
    };

    const _handleSaveAndApprove = async () => {
        uploadFileIfNeeded().then(async () => {
            const request = getModifiedRequest();
            if (request) {
                if (isModified) {
                    setIsLoading(true);
                    await editRequest(request)
                        .then(() => approveRequest(request))
                        .finally(() => setIsLoading(false));
                } else {
                    setIsLoading(true);
                    await approveRequest(request).finally(() => setIsLoading(false));
                }

                props.onSaveAndApprove(request);
            }
        });
    };

    const _handleSave = async () => {
        if (user && user.isOwner && file == null) {
            alert("El archivo PDF es requerido para actualizar la solicitud");
            return;
        }

        const newArea = Number(area);
        const newDensity = Number(density);
        const newGndvi = Number(gndvi);
        const newPermanencyFactor = Number(permanencyFactorValue);

        if (!newArea || newArea <= 0) {
            alert("El área debe ser mayor a 0");
            return;
        }

        if (!newDensity || newDensity <= 0) {
            alert("La densidad debe ser mayor a 0");
            return;
        }

        if (!newGndvi || newGndvi <= 0.01 || newGndvi > 1) {
            alert("El índice verde debe estar entre 0.01 y 1");
            return;
        }

        if (!newPermanencyFactor || newPermanencyFactor <= 0.01 || newPermanencyFactor > 1) {
            alert("El factor de permanencia debe estar entre 0.01 y 1");
            return;
        }

        uploadFileIfNeeded().then(async () => {
            const request = getModifiedRequest();
            if (request) {
                setIsLoading(true);
                await editRequest(request).finally(() => setIsLoading(false));
                props.onSave(request);
            }
        });
    };

    const uploadFileIfNeeded = async () => {
        setIsLoading(true);
        try {
            if (!file) return;
            const reader = new FileReader();
            const contentPromise = new Promise((resolve, reject) => {
                reader.addEventListener("loadend", () => {
                    resolve(reader.result);
                });

                reader.addEventListener("error", reject);
            });

            reader.readAsDataURL(file);
            const content: any = await contentPromise;

            await saveFile(content, props.request._id, `${file.name}`);
            setIsLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Dialog
            fullWidth
            maxWidth="lg"
            open={props.request ? true : false}
            onClose={props.onClose}
        >
            <DialogTitle>
                {props.canApprove ? "Aprobar Solicitud" : "Modificar Solicitud"}
            </DialogTitle>
            <DialogContent dividers>
                <Stack direction="column" gap={2}>
                    <Stack direction="row" gap={2}>
                        <TextField
                            sx={{width: "50%"}}
                            id="outlined-basic"
                            color="secondary"
                            label="ID"
                            variant="outlined"
                            disabled
                            value={props.request._id}
                        />

                        <TextField
                            sx={{width: "50%"}}
                            id="outlined-basic"
                            color="secondary"
                            label="Usuario"
                            variant="outlined"
                            disabled
                            value={props.request.user}
                        />
                        <TextField
                            sx={{width: "50%"}}
                            id="outlined-basic"
                            color="secondary"
                            label="Nombre"
                            variant="outlined"
                            disabled
                            value={[props.request.profile?.name ?? "", props.request.profile?.surname ?? ""].join(" ").trim()}
                        />
                    </Stack>
                    <Stack direction="row" gap={2}>
                        <TextField
                            sx={{width: "33%"}}
                            id="outlined-basic"
                            color="secondary"
                            label="Fecha Alta"
                            variant="outlined"
                            disabled
                            value={moment(props.request.createdAt).format("DD/MM/YYYY")}
                        />
                        <TextField
                            sx={{width: "33%"}}
                            id="outlined-basic"
                            color="secondary"
                            label="Fecha de Siembra"
                            variant="outlined"
                            disabled
                            value={props.request.seedDate}
                        />
                        <TextField
                            sx={{width: "33%"}}
                            id="outlined-basic"
                            color="secondary"
                            label="Fecha fin ciclo agronómico"
                            variant="outlined"
                            disabled
                            value={props.request.harvestDate}
                        />
                    </Stack>
                    <Stack direction="row" gap={2}>
                        <TextField
                            sx={{width: "50%"}}
                            id="outlined-basic"
                            color="secondary"
                            label="Cultivo"
                            variant="outlined"
                            disabled
                            value={props.request.crop}
                        />
                        <TextField
                            sx={{width: "25%"}}
                            id="outlined-basic"
                            color="secondary"
                            label="Semilla"
                            variant="outlined"
                            disabled
                            value={props.request.seedFactor}
                        />
                        <TextField
                            sx={{width: "25%"}}
                            id="outlined-basic"
                            color="secondary"
                            label="Estado"
                            variant="outlined"
                            disabled
                            value={props.request.status}
                        />
                    </Stack>
                    <Stack direction="row" gap={2}>
                        <TextField
                            sx={{width: "100%"}}
                            id="outlined-basic"
                            color="secondary"
                            label="Nombre"
                            variant="outlined"
                            value={name}
                            disabled
                            onChange={(e) => setName(e.target.value)}
                        />
                    </Stack>

                    <Stack direction="row" gap={2}>
                        <TextField
                            sx={{width: "50%"}}
                            id="outlined-basic"
                            color="secondary"
                            label="Densidad"
                            variant="outlined"
                            value={density}
                            disabled={loading}
                            onChange={(e) => {
                                const result = e.target.value.replace(
                                    /[^0-9.]|(?<=\..*)\./g,
                                    ""
                                );
                                setDensity(result);
                            }}
                        />
                        <TextField
                            sx={{width: "50%"}}
                            id="outlined-basic"
                            color="secondary"
                            label="Area Definida"
                            variant="outlined"
                            value={area}
                            disabled={loading}
                            onChange={(e) => {
                                const result = e.target.value.replace(
                                    /[^0-9.]|(?<=\..*)\./g,
                                    ""
                                );
                                setArea(result);
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="start">ha</InputAdornment>
                                ),
                            }}
                        />
                        <TextField
                            sx={{width: "50%"}}
                            id="outlined-basic"
                            color="secondary"
                            label="Índice Verde"
                            variant="outlined"
                            value={gndvi}
                            disabled={loading}
                            onChange={(e) => {
                                const result = e.target.value.replace(
                                    /[^0-9.]|(?<=\..*)\./g,
                                    ""
                                );

                                setGndvi(result);
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="start">GNDVI</InputAdornment>
                                ),
                            }}
                        />

                    </Stack>

                    {user?.isOwner ? (
                        <Stack direction="row" gap={2}>
                            <TextField
                                sx={{width: "50%"}}
                                id="outlined-basic"
                                color="secondary"
                                label="Comisión"
                                variant="outlined"
                                value={commision == 0 ? "" : `${commision}`}
                                disabled={loading}
                                onChange={(e) => {
                                    const text = e.target.value.trim();
                                    if (text.length == 0) {
                                        setCommision(0);
                                        return;
                                    } else {
                                        const val = Number(text);
                                        if (val) {
                                            setCommision(val);
                                        }
                                    }
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start">%</InputAdornment>
                                    ),
                                }}
                            />
                            <TextField
                                sx={{width: "50%"}}
                                id="outlined-basic"
                                color="secondary"
                                label="Duración"
                                variant="outlined"
                                value={duration == 0 ? "" : `${duration}`}
                                disabled={loading}
                                onChange={(e) => {
                                    const text = e.target.value.trim();
                                    if (text.length == 0) {
                                        setDuration(0);
                                        return;
                                    } else {
                                        const val = Number(text);
                                        if (val) {
                                            setDuration(val);
                                        }
                                    }
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start">años</InputAdornment>
                                    ),
                                }}
                            />
                        </Stack>
                    ) : (
                        <></>
                    )}
                    <Stack direction="row" gap={2}>
                        <TextField
                            sx={{width: "50%"}}
                            id="outlined-basic"
                            color="secondary"
                            label="Tratamiento post cosecha"
                            variant="outlined"
                            disabled
                            value={props.request.permanencyFactor?.title}
                        />
                        <TextField
                            sx={{width: "50%"}}
                            id="outlined-basic"
                            color="secondary"
                            label="Factor de permanencia"
                            variant="outlined"
                            value={permanencyFactorValue}
                            disabled={loading}
                            onChange={(e) => {
                                const result = e.target.value.replace(
                                    /[^0-9.]|(?<=\..*)\./g,
                                    ""
                                );

                                setPermanencyFactorValue(result);
                            }}
                        />
                    </Stack>
                    <Stack direction="row" gap={2}>
                        <TextField
                            sx={{width: "50%"}}
                            id="outlined-basic"
                            color="secondary"
                            label="Ton. CO2 Compensado"
                            variant="outlined"
                            disabled
                            value={(props.request.tokensCalculated).toFixed(2)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="start">tn</InputAdornment>
                                ),
                            }}
                        />
                        <TextField
                            sx={{width: "50%"}}
                            id="outlined-basic"
                            color="secondary"
                            label="Cant. TCOE Calculada"
                            variant="outlined"
                            value={props.request.tokensCalculated.toFixed(6)}
                            disabled
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="start">TCOE</InputAdornment>
                                ),
                            }}
                        />
                    </Stack>

                    <Stack direction="row" gap={2}>
                        <Card>
                            <Stack direction="row" alignItems="center">
                                <Checkbox checked={props.request.performsDirectSeeding ?? false} disabled></Checkbox>
                                <div style={{padding: 5}}>Realiza siembra directa</div>
                            </Stack>
                        </Card>

                        <Card>
                            <Stack direction="row" alignItems="center">
                                <Checkbox checked={props.request.performsCropRotation ?? false} disabled></Checkbox>
                                <div style={{padding: 5}}>Realiza Rotación de cultivos</div>
                            </Stack>
                        </Card>

                        <Card>
                            <Stack direction="row" alignItems="center">
                                <Checkbox checked={props.request.performsChemicalInputEfficiency ?? false} disabled></Checkbox>
                                <div style={{padding: 5}}>Realiza eficiencia de insumos químicos</div>
                            </Stack>
                        </Card>

                        <Card>
                            <Stack direction="row" alignItems="center">
                                <Checkbox checked={props.request.performsWaterManagementEfficiency ?? false} disabled></Checkbox>
                                <div style={{padding: 5}}>Realiza eficiencia en gestión del agua</div>
                            </Stack>
                        </Card>

                    </Stack>
                    <Stack direction="row" gap={2}>
                        {props.request.more_information ? <>
                                <div style={
                                    {
                                        position: "relative",
                                        width: "100%",
                                        color: "rgba(255, 255, 255, 0.5)",
                                        border: "1px solid #5B5B66",
                                        borderRadius: 4,
                                        padding: 15
                                    }
                                }>
                                    <div style={
                                        {
                                            position: "absolute",
                                            top: -16,
                                            left: 6,
                                            padding: 5,
                                            fontSize: "0.8rem",
                                            background: "rgba(56, 56, 56, 1)"
                                        }
                                    }>Información adicional del usuario
                                    </div>
                                    <div>{props.request.more_information}</div>
                                </div>
                            </>
                            : <></>}
                    </Stack>

                    {props.request.reject_reason ?
                        <Stack>
                            <TextField
                                id="outlined-basic"
                                color="secondary"
                                label="Motivo del rechazo"
                                variant="outlined"
                                disabled
                                multiline
                                value={props.request.reject_reason}
                            />
                        </Stack>
                        : null
                    }

                    <Stack spacing={2} direction="row">
                        {props.request.img ? (
                            <Button
                                variant="contained"
                                sx={{width: "100%", height: "100%"}}
                                onClick={openImage}
                            >
                                Ver Imagen
                            </Button>
                        ) : (
                            <></>
                        )}
                        <Button
                            variant="contained"
                            sx={{width: "100%", height: "100%"}}
                            onClick={openMap}
                        >
                            Ver Mapa
                            <Map sx={{ml: 1}}></Map>
                        </Button>

                        {user?.isOwner ? (
                            <Button
                                color="warning"
                                variant="contained"
                                sx={{width: "100%"}}
                                disabled={loading}
                                onClick={handleClickOpen}
                            >
                                Solicitar más información al usuario
                            </Button>
                        ) : (
                            <></>
                        )}
                    </Stack>
                    <Stack spacing={2} direction="row">
                        {props.request.other_documentation ? (
                            <Button
                                variant="contained"
                                sx={{width: "100%", height: "100%"}}
                                onClick={openAdditionalFile}
                            >
                                Ver Archivo Adicional
                            </Button>
                        ) : (
                            <></>
                        )}

                        <Button
                            variant="contained"
                            color="secondary"
                            sx={{width: "100%", height: "100%", ...secondaryButtonStyle}}
                            onClick={() => setFileListOpen(true)}
                        >
                            Ver archivos
                        </Button>
                        {user?.isOwner ? (
                            <Button
                                variant="contained"
                                color="secondary"
                                sx={{
                                    width: "100%",
                                    height: "100%",
                                    ...secondaryButtonStyle,
                                }}
                                onClick={() => setLogListOpen(true)}
                            >
                                Ver log de cambios
                            </Button>
                        ) : (
                            <></>
                        )}
                    </Stack>
                    <Stack spacing={2} direction="row">
                        <MuiFileInput
                            placeholder={"Subir PDF"}
                            sx={{width: "100%", height: "100%"}}
                            inputProps={{
                                accept: "application/pdf",
                            }}
                            value={file}
                            onChange={setFile}
                        >
                            Subir Archivo
                        </MuiFileInput>
                    </Stack>
                </Stack>

                <RequestInfoDialog
                    request={props.request}
                    open={requestInfoDialogOpen}
                    onClose={() => setRequestInfoDialogOpen(false)}
                />

                <DialogActions sx={{mt: 4}}>
                    {loading ? <Loader/> : <></>}

                    {isModified ? (
                        props.canApprove ? (
                            <Button
                                variant="contained"
                                color="success"
                                disabled={loading}
                                onClick={_handleSaveAndApprove}
                            >
                                Modificar y aprobar
                            </Button>
                        ) : (
                            <Button
                                variant="contained"
                                color="success"
                                disabled={loading}
                                onClick={_handleSave}
                            >
                                Modificar
                            </Button>
                        )
                    ) : props.canApprove ? (
                        <Button
                            variant="contained"
                            color="secondary"
                            sx={secondaryButtonStyle}
                            disabled={loading}
                            onClick={_handleSaveAndApprove}
                        >
                            Aprobar
                        </Button>
                    ) : (
                        <></>
                    )}

                    <Button
                        variant="contained"
                        disabled={loading}
                        color="secondary"
                        sx={secondaryButtonStyle}
                        onClick={props.onClose}
                    >
                        Cancelar
                    </Button>
                </DialogActions>
            </DialogContent>
            <FileList
                open={fileListOpen}
                files={props.request.files ?? []}
                onClose={() => setFileListOpen(false)}
            />
            <LogList
                request={props.request}
                open={logListOpen}
                onClose={() => setLogListOpen(false)}
            />
            <MapModal
                request={props.request}
                open={mapOpen}
                onClose={() => setMapOpen(false)}
            />
        </Dialog>
    );
};

import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Divider,
  Drawer,
  List,
  ListItemButton,
  ListItemText,
  Toolbar,
} from "@mui/material";
import useAuth from "../../Auth/useAuth";
import theme from "../../../theme";

const Sidebar = () => {
  const [selected, setSelected] = useState<number | null>(null);
  const navigate = useNavigate();
  const { user } = useAuth() || {};

  const routes = useMemo(
    () => [
      { title: "Oráculos", hidden: !user?.isOwner, path: "/auditors" },
      { title: "Solicitudes", hidden: !user, path: "/requests" },
      { title: "Bonos y Créditos", hidden: !user?.isOwner, path: "/bonds" },
      { title: "Usuarios", hidden: !user?.isOwner, path: "/users" },
      {
        title: "Monitoreo de Eventos",
        hidden: !user?.isOwner,
        path: "/events",
      },
      { title: "Configuración", hidden: !user?.isOwner, path: "/configuration" },
    ],
    [user]
  );

  const handleSelect = (index: number) => {
    setSelected(index);
    const routesFiltered = routes.filter((x) => !x.hidden);
    navigate(routesFiltered[index].path);
  };

  return (
    <Drawer
      sx={{
        width: 160,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: 160,
          boxSizing: "border-box",
          backgroundColor: theme.palette.secondary.main,
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <Toolbar />
      <Divider />
      <List>
        {routes
          .filter((x) => !x.hidden)
          .map(({ title }, i) => (
            <ListItemButton
              key={title}
              selected={selected === i}
              onClick={() => handleSelect(i)}
            >
              <ListItemText
                primary={title}
                sx={{
                  color: theme.palette.primary.main,
                }}
              />
            </ListItemButton>
          ))}
      </List>
    </Drawer>
  );
};

export default Sidebar;

import {
  BtnAccept,
  BtnDeny,
  BtnProcessing,
  BtnRevision,
  ContainerBtns,
  SpanCopy,
} from "./nftRequestsStyles";
import {
  NftRequestProps,
  NftRequestStatus,
} from "../../containers/NFTRequests/types";
import moment from "moment";
import { Add, Download, Map, Remove } from "@mui/icons-material";
import useNftRequests from "../../containers/NFTRequests/useNftRequests";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { getCenterOfPolygon } from "../../helpers/geolib";
import web3storage from "../../services/web3storage";
import { useNavigate } from "react-router-dom";

interface Props {
  request: NftRequestProps;
  approveRequest: (request: NftRequestProps) => void;
  updateRequest: (request: NftRequestProps, state: string) => void;
  modifyRequest: (request: NftRequestProps) => void;
  processRequest: (request: NftRequestProps) => void;
  rejectRequest: (request: NftRequestProps) => void;
  filter: string;
  pdfBase64: string | null;
}

export const NFTRequestsChild = ({
  request,
  approveRequest,
  updateRequest,
  modifyRequest,
  processRequest,
    rejectRequest,
  filter,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const { buttonLoading, getCrop, saveFile, user } = useNftRequests();

  const navigate = useNavigate();

  const imgUrl = request.img;
  const certificateUrl = request.nft
  const centerPolygon = getCenterOfPolygon(request.markers);

  const openMap = () => {
    window.open(
      `https://www.google.com.sa/maps/search/${centerPolygon?.latitude},${centerPolygon?.longitude}`,
      "_blank"
    );
  };

  const handleDownload = (url: string) => {
    window.open(url, "_blank");
  };

  const handleCopy = () => {
    alert("Copiado al portapapeles.");
  };

  const handleRejection = () => {
    if (window.confirm("¿Estás seguro de rechazar esta solicitud?")) {
      rejectRequest(request)
    }
  };

  const handleReview = () => {
    modifyRequest(request);
    // if (window.confirm("¿Estás seguro de solicitar una revisión?")) {
    //   updateRequest(request, "review");
    // }
  };

  const handleProcess = () => {
    if (window.confirm("¿Estás seguro de procesar la solicitud?")) {
      processRequest(request);
    }
  };

  const handleHarvest = () => {
    const date = moment(request.harvestDate, "DD-MM-YYYY");
    return moment(date).isSameOrBefore(moment());
  };

  const handleApprove = () => {
    try {
      const isHarvest = handleHarvest();
      if (isHarvest) {
        if (window.confirm("¿Estás seguro de aprobar esta solicitud?")) {
          approveRequest(request);
        } else {
          alert("Cancelaste la solicitud");
        }
      } else {
        alert("Todavía no terminó el ciclo de vida del cultivo.");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleNavigate = () => {
    navigate(`/requests/${request._id}`);
  };

  const inputRef = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    inputRef.current?.click();
  };

  const tokensToFixed = request?.tokensCalculated.toFixed(10);

  useEffect(() => {
    // getCrop();
  }, []);

  return (
    <>
      <TableRow
        key={request._id}
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
        }}
      >
        {filter === "approved" ? (
          <TableCell>
            {certificateUrl && (
              <Download
                onClick={() => handleDownload(certificateUrl)}
                style={{ color: "black", cursor: "pointer" }}
              />
            )}
          </TableCell>
        ) : null}
        <TableCell sx={{ color: "white" }} component="th" scope="row">
          {request._id.slice(0, 5)}...{" "}
          <CopyToClipboard text={request._id} onCopy={() => handleCopy()}>
            <SpanCopy>
              <ContentCopyIcon
                style={{
                  width: "15px",
                }}
              />
            </SpanCopy>
          </CopyToClipboard>
        </TableCell>
        <TableCell sx={{ color: "white" }}>
          {request.user.slice(0, 5)}...
          <CopyToClipboard text={request.user} onCopy={() => handleCopy()}>
            <SpanCopy>
              <ContentCopyIcon
                style={{
                  width: "15px",
                }}
              />
            </SpanCopy>
          </CopyToClipboard>
        </TableCell>
        <TableCell sx={{ color: "white" }}>
          {[request.profile?.name ?? "", request.profile?.surname ?? ""].join(" ").trim()}
          <CopyToClipboard text={[request.profile?.name ?? "", request.profile?.surname ?? ""].join(" ").trim()} onCopy={() => handleCopy()}>
            <SpanCopy>
              <ContentCopyIcon
                  style={{
                    width: "15px",
                  }}
              />
            </SpanCopy>
          </CopyToClipboard>
        </TableCell>
        <TableCell
          sx={{ color: "white" }}
        >
          {request.propertyName}
        </TableCell>
        <TableCell sx={{ color: "white" }}>{request.status}</TableCell>
        <TableCell sx={{ color: "white" }}>{request.crop}</TableCell>
        <TableCell sx={{ color: "white" }}>{request.density}</TableCell>
        <TableCell sx={{ color: "white" }}>{request.seedFactor}</TableCell>

        <TableCell sx={{ color: "white" }}>
          {moment(request.createdAt).locale("es").format("DD-MM-YYYY HH:mm")}
        </TableCell>
        <TableCell sx={{ color: "white" }}>{request.harvestDate}</TableCell>
        <TableCell sx={{ color: "white" }}>
          {request.area < Number(request.calculatedArea) ? (
            <div>
              <div>{request.area} ha</div>
              <div>
                <Remove />
              </div>
            </div>
          ) : request.area > Number(request.calculatedArea) ? (
            <div>
              <div>{request.area} ha</div>
              <div>
                <Add />
              </div>
            </div>
          ) : (
            <div>{request.area} ha</div>
          )}
        </TableCell>
        <TableCell sx={{ color: "white" }}>
          {request.tokensCalculated.toFixed(10)}
        </TableCell>
        <TableCell sx={{ color: "white" }}>
          {tokensToFixed}
          <CopyToClipboard
            text={tokensToFixed.toString()}
            onCopy={() => handleCopy()}
          >
            <SpanCopy>
              <ContentCopyIcon
                style={{
                  width: "15px",
                }}
              />
            </SpanCopy>
          </CopyToClipboard>
        </TableCell>
        {filter === "approved" ? (
          <TableCell sx={{ color: "white" }}>
            {request.tokensGiven || "-"}
          </TableCell>
        ) : null}
        <TableCell>
          {imgUrl && (
            <Download
              onClick={() => handleDownload(imgUrl)}
              style={{ color: "black", cursor: "pointer" }}
            />
          )}
        </TableCell>
        <TableCell>
          {centerPolygon && (
            <Map onClick={openMap} style={{ cursor: "pointer" }} />
          )}
        </TableCell>
        <TableCell>
          {request.status === "pending" ? (
            <ContainerBtns>
              {/* <BtnAccept onClick={() => handleApprove()}>Aprobar</BtnAccept> */}
              <BtnProcessing
                disabled={buttonLoading}
                onClick={() => handleProcess()}
              >
                Procesar
              </BtnProcessing>
              <BtnDeny
                disabled={buttonLoading}
                onClick={() => handleRejection()}
              >
                Rechazar
              </BtnDeny>
              <BtnRevision
                disabled={buttonLoading}
                onClick={() => handleReview()}
              >
                Revisar
              </BtnRevision>
            </ContainerBtns>
          ) : request.status === "review" ? (
            <ContainerBtns>
              {!user?.isOwner ? (
                <BtnAccept
                  disabled={buttonLoading}
                  onClick={() => approveRequest(request)}
                >
                  Aprobar
                </BtnAccept>
              ) : (
                <></>
              )}
              <BtnProcessing
                disabled={buttonLoading}
                onClick={() => handleProcess()}
              >
                Procesar
              </BtnProcessing>
              <BtnDeny
                disabled={buttonLoading}
                onClick={() => handleRejection()}
              >
                Rechazar
              </BtnDeny>
              <BtnRevision
                disabled={buttonLoading}
                onClick={() => handleReview()}
              >
                Revisar
              </BtnRevision>
            </ContainerBtns>
          ) : request.status === "rejected" ? (
            <ContainerBtns>
              <BtnRevision
                disabled={buttonLoading}
                onClick={() => handleReview()}
              >
                Revisar
              </BtnRevision>
            </ContainerBtns>
          ) : request.status === "approved" ? null : request.status ===
              "process" || filter === "approved_by_auditor" ? (
            <ContainerBtns>
              {user &&
              (user.isOwner == false ||
                (user.isOwner && filter === "approved_by_auditor")) ? (
                <BtnAccept
                  disabled={buttonLoading}
                  onClick={() => approveRequest(request)}
                >
                  Aprobar
                </BtnAccept>
              ) : (
                <></>
              )}
              <BtnRevision
                disabled={buttonLoading}
                onClick={() => handleReview()}
              >
                Revisar
              </BtnRevision>
              <BtnDeny
                disabled={buttonLoading}
                onClick={() => handleRejection()}
              >
                Rechazar
              </BtnDeny>
            </ContainerBtns>
          ) : null}
        </TableCell>
      </TableRow>
    </>
  );
};

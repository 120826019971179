export enum EnumRoles {
  pollutant = "pollutant",
  sustainable = "sustainable",
  investor = "investor",
}

export interface IdentificationProps {
  number: string;
  type: "CUIT" | "CUIL";
}

export type GenreProps = "M" | "F" | "X";

export interface DNIValues {
  name: string;
  surname: string;
  genre: GenreProps;
  dni: string;
}

export interface BaseUserProps extends DNIValues {
  email: string;
  identification: IdentificationProps;
}

export interface UserProps extends BaseUserProps {
  _id: string;
  role?: EnumRoles;
  address?: string;
  createdAt: Date;
  updatedAt: Date;
  validated?: boolean;
  documents?: [{
    _id: string,
    url: string,
    doctype: string
  }]
  FCM_TOKEN?: string;
}

export function mapRole(roleStr: string): string {
  switch (roleStr){
    case 'owner': return "Administrador"
    case 'auditor': return "Oráculo"
    case 'user': return "Usuario"
    default: return roleStr
  }
}

export function mapChanges(values: string[]): string[] {
  let changes: string[] = [];

  values.forEach((value) => {
    switch (value) {
      case 'created':
        changes.push("Solicitud Creada"); break;
      case 'propertyName':
        changes.push("Nombre"); break;
      case 'area':
        changes.push("Área"); break;
      case 'density':
        changes.push("Densidad"); break;
      case 'crop':
        changes.push("Cultivo"); break;
      case 'seedFactor':
        changes.push("Factor Semilla"); break;
      case 'seedDate':
        changes.push("Fecha de Siembra"); break;
      case 'img':
        changes.push("Imagen"); break;
      case 'commission':
        changes.push("Comisión"); break;
      case 'duration':
        changes.push("Duración"); break;
      case 'file_uploaded':
        changes.push("Archivo Agregado"); break;
      case 'permanency_factor_value':
        changes.push("Factor de Permanencia"); break;
      case 'sent_to_process':
        changes.push("Asignado a oráculo"); break;
      case 'approved_by_auditor':
        changes.push("Aprobado por oráculo"); break;
      case 'requested_more_info':
        changes.push("Solicitud de información"); break;
      default:
        changes.push(value); break;
    }
  })

  return changes;
}
import { useCallback, useState } from "react";
import axios from "../../services/axios";
import { ethers } from "ethers";
import { useWallet } from "../Wallet";
import { useNotify } from "../Notify";
import { NftRequestProps } from "./types";
import { nftRequestApi, profile } from "../../services/api";
import useNotifications from "../../services/notifications/useNotifications";
import web3storage from "../../services/web3storage";
import useAuth from "../Auth/useAuth";
import moment from "moment";

const useNftRequests = () => {
  const { nftRequestContract } = useWallet();
  const { user, logout } = useAuth() || {};
  const { notification } = useNotify();
  const { createNotification } = useNotifications();
  const [nftRequests, setNftRequests] = useState<NftRequestProps[]>([]);
  const [nftRequestsHarvested, setNftRequestsHarvested] = useState<
    NftRequestProps[]
  >([]);
  const [nftRequestsNotHarvested, setNftRequestsNotHarvested] = useState<
    NftRequestProps[]
  >([]);

  const [page, setPage] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [filter, setFilter] = useState<string | null>(null);
  const [search, setSearch] = useState<string>("");
  const [crops, setCrops] = useState<any[]>([]);
  const [pdfBase64, setPdfBase64] = useState<string | null>(null);
  const [files, setFiles] = useState<any[]>([]);

  const getNFTRequests = async () => {
    try {
      if (!filter) {
        return;
      }
      setLoading(true);
      let result;
      if (search) {
        result = await axios.get(`/request?page=${page}&filter=${filter}`, {
          params: { search },
        });
      } else {
        result = await axios.get(`/request?page=${page}&filter=${filter}`);
      }
      if (result.data) {
        setPage(result.data.page);
        setPageCount(result.data.pageCount);
      }
      const nftReqs = result.data.items;
      if (nftReqs) {
        const harvestedRequests = nftReqs.filter((req: NftRequestProps) => {
          if (req.harvestDate) {
            const date = moment(req.harvestDate, "DD-MM-YYYY");
            return moment(date).isSameOrBefore(moment());
          }
          return false;
        });

        const notHarvestedRequests = nftReqs.filter((req: NftRequestProps) => {
          if (req.harvestDate) {
            const date = moment(req.harvestDate, "DD-MM-YYYY");
            return moment(date).isAfter(moment());
          }
          return false;
        });

        setNftRequests(nftReqs);
        setNftRequestsHarvested(harvestedRequests);
        setNftRequestsNotHarvested(notHarvestedRequests);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const editRequest = async (request: NftRequestProps) => {
    return nftRequestApi.edit(request);
  };

  const getCrop = async () => {
    try {
      const result = await axios.get("/request/crops");
      if (result.data) {
        setCrops(result.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const approveRequest = useCallback(
    async (request: any) => {
      const currentUser = user;
      if (currentUser) {
        const {
          activity,
          crop,
          propertyName,
          density,
          area,
          calculatedArea,
          seedDate,
          seedFactor,
          expireDate,
          tokensCalculated,
          auditor,
          createdAt,
          // eslint-disable-next-line @typescript-eslint/no-shadow
          user,
          _id,
        } = request;

        const harvestDate = moment(request.harvestDate, "DD/MM/YYYY");
        if (harvestDate.isAfter(moment().startOf("day"))) {
          alert("Debe esperar al fin del ciclo agronómico");
          return;
        }

        if (currentUser.isOwner) {
          setButtonLoading(true);
          try {
            const tokensGiven = request.tokensCalculated.toFixed(6);
            const confirmed = confirm(
              `Cantidad de TCOE a transferir: ${request.tokensCalculated.toFixed(
                6
              )}`
            );
            if (!confirmed) {
              return;
            }

            const commission = request.commission ?? 30;

            await nftRequestApi.approve(
              _id,
              (+tokensGiven * (100 - commission)) / 100
            );

            const userProfile = await profile.getProfileByAddress(user);
            await createNotification({
              title: `Solicitud de NFT para ${propertyName} aceptada`,
              body: `Recibiste ${+tokensGiven * 0.7} TCOE`,
              tokens: [userProfile?.FCM_TOKEN!],
              profile: userProfile?._id,
              navigateTo: {
                stack: "Mis NFTs",
                screen: "Mis NFT",
              },
            });

            notification(
              "approveRequest",
              "success",
              "NFT creado exitosamente!"
            );
            setButtonLoading(false);
          } catch (error) {
            console.log(error);
            notification("approveRequest", "error", (error as Error).message);
            setButtonLoading(false);
          }
        } else {
          await nftRequestApi.approveWithAuditor(_id);
        }
      }
    },
    [nftRequestContract, notification]
  );

  const processRequest = async (request: NftRequestProps) => {
    return nftRequestApi.process(request._id);
  };

  const rejectRequest = async (request: NftRequestProps, reason: string) => {
    return nftRequestApi.reject(request._id, reason);
  };

  const requestMoreInfo = async (request: NftRequestProps, reason: string, observations: string) => {
    return nftRequestApi.requestMoreInfo(request._id, reason, observations)
  };


  const updateRequest = useCallback(
    async (
      request: any,
      status: string,
      reason?: string,
      observations?: string
    ) => {
      try {
        setButtonLoading(true);
        notification("updateRequest", "hint", "Actualizando solicitud...");
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const { propertyName, user, _id } = request;
        await axios.put(`/request/changestatus/${_id}`, {
          status,
          reason,
          observations,
        });
        const userProfile = await profile.getProfileByAddress(user);
        if (status === "rejected") {
          await createNotification({
            title: `Novedades sobre tu solicitud de NFT para ${propertyName}`,
            body: "Estado: rechazada",
            tokens: [userProfile?.FCM_TOKEN!],
            profile: userProfile?._id,
            navigateTo: {
              stack: "Perfil",
              screen: "Mis Solicitudes",
            },
          });
        } else if (status === "review") {
          await createNotification({
            title: `Novedades sobre tu solicitud de NFT para ${propertyName}`,
            body: "Revisar información brindada",
            navigateTo: {
              stack: "Perfil",
              screen: "Mis Solicitudes",
            },
            tokens: [userProfile?.FCM_TOKEN!],
            profile: userProfile?._id,
          });
        }

        notification(
          "updateRequest",
          "success",
          "Solicitud actualizada exitosamente."
        );
        setButtonLoading(false);
      } catch (error) {
        setButtonLoading(false);
        notification("updateRequest", "error", (error as Error).message);
      }
    },
    [notification]
  );

  const getPdfFile = (file: any) => {
    try {
      setLoading(true);
      const fetchData = async () => {
        window.open(file.url)
      };
      fetchData();
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const saveFile = async (fileContent: string, id: string, name: string) => {
    try {
      setLoading(true);
      const result = await axios.post(`/request/${id}/add_file`, {
        file: fileContent,
        name: name,
        id,
      });
      console.log(result.data.message);
      notification("updateRequest", "success", result.data.message);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getFiles = async (id: string) => {
    try {
      setLoading(true);
      const result = await axios.get(`/request/getfiles/${id}`);
      if (result.data) {
        setFiles(result.data);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getLogs = async (id: string) => {
    try {
      setLoading(true);
      const result = await axios.get(`/request/${id}/logs`);
      return result.data;
    } catch (error) {
      console.log(error);
    }
  };

  return {
    user,
    nftRequests,
    nftRequestsHarvested,
    nftRequestsNotHarvested,
    getNFTRequests,
    approveRequest,
    rejectRequest,
    updateRequest,
    processRequest,
    requestMoreInfo,
    editRequest,
    page,
    setPage,
    pageCount,
    loading,
    filter,
    setFilter,
    buttonLoading,
    search,
    setSearch,
    getCrop,
    crops,
    getPdfFile,
    pdfBase64,
    saveFile,
    getFiles,
    files,
    getLogs,
  };
};

export default useNftRequests;
